import React from 'react'
import {Row, Container, Col, Button } from 'react-bootstrap';

import { BgDesktop, Bgdiv, Head, BgMob} from './ProductHeadStyles';

const SolutionHeader = () => {

  return (

<Container fluid  style={myComponentStyle}>
  <Bgdiv>
  <BgMob/>

  <BgDesktop/>
      <Head>Better Security, Better World.</Head>
  </Bgdiv>
</Container>

    
  )
}

const myComponentStyle = {
  paddingLeft: 0,
  paddingRight: 0,
  height: '450px',
  position : 'relative',
}

export default SolutionHeader

