import React from 'react'
import Hero from '../components/Hero/Hero'
import Cooperation from '../components/Cooperation/Cooperation'
import Address from '../components/Contact/Address/Address'
import { ContactDiv } from '../components/Contact/ContactDiv/ContactDiv'
import Map  from '../components/Contact/Map/Map'

const location = {
  address: '1600 Amphitheatre Parkway, Mountain View, california.',
  lat: 55.628809,
  lng: 25.341727,
} // our location object from earlier


const Contact = () => {
  return (
    <>
      <Hero />
      <ContactDiv />
      {/* <Address /> */}
      <Cooperation />
      <Map location={location} zoomLevel={17} />
    </>
  )
}

export default Contact