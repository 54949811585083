export const CooperationData = [
	{
		topText: 'Customer-First',
		downText: 'Flexible and customized sales,market and brand strategy.',
		image: './assets/cm1.jpg',
	},
	{
		topText: 'Delivery',
		downText: 'First-rate delivery ability',
		image: './assets/cm2.jpg',
	},
	{
		topText: 'Scale',
		downText: 'Pcs of sale is increasing day to day.',
		image: './assets/cm3.jpg',
	},

]