import React from 'react';
import GlobalStyle from './globalStyles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';

//Pages
import Home from './pages/Home';
// import SignUp from './pages/SignupPage';
// import Pricing from './pages/PricingPage';
import Footer from './components/Footer/Footer';
import { About } from './pages/About';
import { Partner } from './components/Partner/Partner';
import Product from './pages/Product';
import Contact from './pages/Contact';

function App() {
	return (
		<Router>
			<GlobalStyle />
			<Navbar />
			<Partner />
			<Switch>
				<Route path="/" exact component={Home} />
				<Route path="/Product" exact component={Product} />
				<Route path="/About" exact component={About} />
				<Route path="/Contact" exact component={Contact} />
			</Switch>
			<Footer />
		</Router>
	);
}

export default App;