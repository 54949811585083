import styled from 'styled-components';


export const BgDesktop = styled.div`
//   background-image: url(./assets/tech_bg.jpg);
//   height:100%;
//   margin-left: -250px;
//   position:relative;
//   background-repeat: round;
//   @media screen and (max-width: 568px) {
//     display:none;
// }
@media screen and (min-width: 568px) {
  background-image: url(./assets/tech_bg.jpg);
  height:100%;
  margin-left: -250px;
  position:relative;
  object-fit:cover;
  // background-repeat: round;
  }
@media screen and (min-width: 1687px) {
  background-repeat: round;
  }
  @media screen and (max-width: 568px) {
    background-image: url(./assets/tech_bg1.jpg);
    height:100%;
    margin-left: -330px;
    background-repeat: no-repeat;
  }
`;

export const BgMob = styled.div`
  // background-image: url(./assets/tech_bg1.jpg);
  // height:100%;
  // margin-left: -330px;
  // background-repeat: no-repeat;
  // @media screen and (min-width: 568px) {
  //   display:none;
  // }
`;

export const Bgdiv = styled.div`
  height: 100%;

//   @media screen and (max-width: 568px) {
//     margin-left: -1250px;
//     background-size: 300px 100px;
// }
`;

export const Head = styled.h1`

@media screen and (min-width: 568px) {
position: absolute;
top: 188px;
left: 15vw;
color:white;
}
@media screen and (max-width: 568px) {
  margin-top: -164px;
  text-align: center;
  color:white;
}
`;


