
import React from 'react'
// import GoogleMapReact from 'google-map-react'
// import { Icon } from '@iconify/react'
// import locationIcon from '@iconify/icons-mdi/map-marker'

import './map.css'

// const LocationPin = ({ text }) => (
//   <div className="pin">
//     <Icon icon={locationIcon} className="pin-icon" />
//     <p className="pin-text">{text}</p>
//   </div>
// )

const Map = ({ location, zoomLevel }) => (
  <div>
  <div class="container-fluid" id="whychose" >
  <div class="row">
    <div class="col-md-12 mt-3">
    <div className="google-map-code" style={{margin: "0px -12px;"}}>
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.8818713930996!2d55.626629250635524!3d25.34174468374974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f3a925bebdd5%3A0x6ebb04577f4e5f33!2sAL%20KHIDMA%20AL%20THABIA%20TYPING!5e0!3m2!1sen!2sae!4v1642598298440!5m2!1sen!2sae" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.8822052104283!2d55.62662015063557!3d25.341733483749806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f3508d6c5555%3A0xe224791a6e6df77a!2sALNOKHATHA%20Security%20Solutions%20-%20UNV%20CCTV%20Camera%20Sales%20and%20Installations!5e0!3m2!1sen!2sae!4v1663058414176!5m2!1sen!2sae" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
          </div>
    </div>
  </div>
</div>
</div>
)

// const Map = ({ location, zoomLevel }) => (
//   <div className="map">
//     <h2 className="map-h2">Come Visit Us At Our Campus</h2>
//     <div className="google-map">
//       <GoogleMapReact
//         bootstrapURLKeys={{ key: '' }}
//         defaultCenter={location}
//         defaultZoom={zoomLevel}
//       >
//         <LocationPin
//           lat={location.lat}
//           lng={location.lng}
//           text={location.address}
//         />
//       </GoogleMapReact>
//     </div>
//   </div>
// )

export default Map