import styled from "styled-components"

export const Fig = styled.div`
    font-family: 'Raleway', Arial, sans-serif;
    position: relative;
    overflow: hidden;
    margin: 10px;
    width: 100%;
    background: #ffffff;
    color: #000000;
    height:500px;
`;

export const ACol = styled.div`
box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
background:red;
margin : 30px;
`;

