import React from 'react';
// import Carousel from '../components/Carousel/Carousel';
// import { Content } from '../components/Content/Content';
import Features from '../components/Features/Features';
import Hero from '../components/Hero/Hero';
import TopSlider from '../components/TopSlider/TopSlider'
import DivSlider from '../components/DivSlider/DivSlider';
import SuccessDiv from '../components/SuccessDiv/SuccessDiv';
// import ScrollHandler from "./components/ScrollHandler";



// Hero Feature Content Carousel

const Home = () => {
	return (
		<>
			<TopSlider />
			<DivSlider />
			{/* <SuccessDiv /> */}
			<Hero />
			<Features />
			{/* <Carousel /> */}
		</>
	);
};

export default Home;