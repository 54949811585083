import styled from 'styled-components';

export const LMain = styled.div`
        border: 2px solid rgba(var(--b6a,219,219,219),1);
        border-radius: 10px;
        margin: 10px 0 45px;
        padding: 10px 0;
        height: 330px;
`;

export const ProductImg = styled.img`
    object-fit:cover;
    height: 80%;
    padding: 36px;
    width: 100%;
    margin: auto;
    display: block;
    :hover {
        transform: scale(1.55);
        -webkit-transition: transform 1s ease-in-out;
      }
`;

export const PTitle = styled.h1`
text-align:center;
font-size: 1.3rem;
`;

export const SBreak = styled.hr`
width:20%;
margin: auto;
display: block;
height: 2px;
margin-bottom: 10px;
`;

export const LHead = styled.h1`
    color: #39425f;
    font-weight: bold;
`;



