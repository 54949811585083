import styled from "styled-components"

export const divStyle={
    padding:'80px 0',
    backgroundColor: '#071C2F',
}

export const colStyle={
    boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
    margin: '20px',
    paddingRight : '0',
    backgroundColor: 'white',
}

export const MainBox = styled.div`
padding: 80px 0 250px 0;
transition: all .5s;
margin-buttom:128px;
`;

export const MainText = styled.h1`
font-size: 39px;
font-family: ex;
color: white;
font-weight: bold;
text-align: center;
`;

export const Box = styled.div`
width: 73%;
max-width: 1400px;
min-width: 1200px;
margin: 0 auto;
position: relative;
`;

export const UL = styled.ul`
margin-top: 40px;
list-style-type: none;
`;

export const LI = styled.li`
width: 31.5%;
    float: left;
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    margin-right: 2.75%;
`;

export const Head = styled.li`
    width: 31.5%;
    float: left;
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    margin-right: 2.75%;
`;


export const TopText = styled.div`
    padding: 70px 5% 20px;
    color: #39425f;
    font-size: 32px;
`;

export const TopLine = styled.div`
    width: 30px;
    height: 2px;
    background: #2689c3;
    margin: 0 0 30px 5%;
`;

export const DownText = styled.div`
    height: 75px;
    padding: 0 5%;
    line-height: 1.5;
`;

export const Divimg = styled.img`
    display: block;
    width: 100%;
`;