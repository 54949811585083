

import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';

import { DivSliderData} from '../../data/DivSliderData';
import { DivOneSecion, DivFeatures, DivFeature, DivTitle, DivHeadText, DivImage } from './DivSliderStyle';
import {Row, Container, Col } from 'react-bootstrap';
const DivSlider = () => {
  return (
    <div>
            <Carousel style={{marginTop:"50px", marginBottom:"50px"}} id="Series">
            {DivSliderData.map((d, index) => (
                <Carousel.Item key={index}>
                <Container className='mt-5 mb-5' style={{padding:"0px 50px"}}>
                    <Row>
                        <Col md={8}>
                            <DivOneSecion>
                            <DivTitle>{d.title}</DivTitle>
                            <DivHeadText>{d.head}</DivHeadText>
                            {d.features.map((feature, index) => (
                            <DivFeatures  key={index}>
                                <DivFeature>{feature}</DivFeature>
                            </DivFeatures>
                            ))}
                            </DivOneSecion>
                            
                        </Col>
                        <Col md={4}>
                            <DivImage>
                            <img src={d.image} />
                            </DivImage>
                        </Col>
                    </Row>
                </Container>
             
                </Carousel.Item>
                   ))}
            </Carousel>
    </div>
  )
}

export default DivSlider
