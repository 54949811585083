import React from 'react';
import { PSection, PText } from './PartnerStyles';

export const Partner = () => {
  return (
    <PSection>
        <PText>

        An Authorized UNV Partner
        </PText>
        
        </PSection>
  )
}
