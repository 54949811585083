import styled from 'styled-components';

export const Bg = styled.div`
	padding: 160px 0;
  background-image: url("./assets/bg1.jpg");
  width:100%;
  height:550px;
`;

export const DivTextOne = styled.h1`
 
`;