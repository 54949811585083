import styled from 'styled-components';

export const DivOneSecion = styled.div`
    margin-left:5%;
	width:100%;
`;


export const DivTitle = styled.h1`
	margin-bottom: 5px;
	font-size: 3.5rem;
    font-weight:600;
   
`;

export const DivHeadText = styled.h3`
	margin-bottom: 5px;
	font-size: 2rem;
    color: #828080;
`;

export const DivFeatures = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
    padding-left: 0rem!important;
    margin-top:0px!important;
    margin-bottom:0px!important;
`;

export const  DivFeature = styled.li`
	margin-bottom: 0.2rem;
	display: flex;

`;

export const  DivImage = styled.div`
// padding: 21%;
box-sizing: border-box;

`;