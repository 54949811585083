import SimpleImageSlider from "react-simple-image-slider";
import { TopSliderStyles } from './TopSliderStyles';
import React, { Component }  from 'react';

const images = [
  { url: "./assets/slider/5.jpg" },
  { url: "./assets/slider/1.jpg" },
  { url: "./assets/slider/2.jpg" },
  { url: "./assets/slider/3.jpg" },
  { url: "./assets/slider/4.jpg" },
];

const TopSlider = () => {
  return (
    <TopSliderStyles>
      <SimpleImageSlider
        width={'100%'}
        height={'50vw'}
        images={images}
        showBullets={true}
        showNavs={true}
        autoPlay={true}/>
    </TopSliderStyles>
  );
}

export default TopSlider;