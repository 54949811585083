import React from 'react'
import { Content } from '../components/Content/Content'
import { heroOne, heroTwo, heroThree } from '../data/HeroData';
import TopSlider from '../components/TopSlider/TopSlider';

export const About = () => {
  return (
    <>
      <TopSlider />
    	<Content {...heroOne} />
			<Content {...heroTwo} />
			<Content {...heroThree} />
    </>
  )
}
