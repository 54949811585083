import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { CooperationData } from '../../data/CooperationData'
import { divStyle, colStyle,  MainText, TopText, DownText, TopLine, Divimg} from './CooperationStyles';

const Cooperation = () => {
  return (
    <>
    <Container fluid style={divStyle}>
        <Container>
        <MainText >Cooperation multiples the success</MainText>
       
        <Row className="mt-5">
        {CooperationData.map((c, index) => (
            <>
                <Col style={colStyle} key={index}>          
                    <TopText>{c.topText}</TopText>
                    <TopLine />
                    <DownText>{c.downText}</DownText>
                    <Divimg src={c.image}/>
                </Col>
            </>
        ))}
        </Row>
    </Container>
</Container>
    </>
  )
}

export default Cooperation