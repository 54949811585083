import React from 'react'
import {Row, Container, Col, Button } from 'react-bootstrap';
import { LMain, ProductImg, PTitle, SBreak, LHead } from './ProductListStyles';
import { ProductListData } from '../../../data/ProductListData';

const ProductList = () => {
  return (
    <Container className="mt-5">
        <LHead>All Products</LHead>
        <Row>
        {ProductListData.map((d, index) => (
            <Col md={3} key={index}>
            <LMain>
                <ProductImg src={d.image} />
                <SBreak />
                <PTitle>{d.title}</PTitle>
            </LMain>              
            </Col>
            ))}
        </Row>
    </Container>
  )
}



export default ProductList