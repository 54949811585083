import React from 'react';
import {
	FooterLinkItems,
	FooterLinkTitle,
	FooterLink,
	FooterLogo,
	SocialIcon,
	FooterRights,
	FooterSocialIcon,
	FooterWrapper,
	FooterAddress,
	FooterColumn,
	FooterGrid,
	Whatsapp,
	BLinkStyle,
} from './FooterStyles';
import { footerData, footerSocialData } from '../../data/FooterData';
import { Row, Section } from '../../globalStyles';

import { FaWhatsapp } from "react-icons/fa";
import { Link as BLink } from 'react-router-dom'

// import {Link} from 'react-scroll'

function Footer() {

const handleClick = () => {
		window.location.replace('https://web.whatsapp.com/send?phone=971563800082')
	  }

	return (
		<Section padding="4rem 0 2rem 0">
			<FooterWrapper>
				<FooterGrid justify="space-between">
					<FooterColumn id="footerLogo">
						<FooterLogo to="/">
							<SocialIcon src="./assets/logo.png" />
							UNVUAE
						</FooterLogo>
						<FooterAddress>
							EMIRATES INDUSTRIAL CITY, SHARJAH, UAE
						</FooterAddress>

						<Row align="center" margin="auto  0 0 0" gap="1rem">
							{footerSocialData.map((social, index) => (
								<FooterSocialIcon
									key={index}
									href={social.link}
									target="_blank"
									aria-label={social.name}
								>
									{social.icon}
								</FooterSocialIcon>
							))}
						</Row>
					</FooterColumn>
					{footerData.map((footerItem, index) => (
						<FooterLinkItems key={index}>
							<FooterLinkTitle>{footerItem.title}</FooterLinkTitle>
							{footerItem.links.map((link, linkIndex) => (
								<FooterLink key={linkIndex} >
										<BLink style={BLinkStyle} to={link.page}>{link.name}</BLink>
								</FooterLink>
							))}
						</FooterLinkItems>
					))}
				</FooterGrid>
				<FooterRights>UNVUAE © 2022</FooterRights>
				<Whatsapp onClick={handleClick }><FaWhatsapp style={{marginTop:'12px'}}/></Whatsapp>
			</FooterWrapper>

		</Section>
	);
}
export default Footer;