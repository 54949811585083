import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
	{
		name: 'Facebook',
		link: 'https://facebook.com/Alnokhathasolutions-102160995508369',
		icon: iconStyle(FaFacebook),
	},
	{
		name: 'Instagram',
		link: 'https://www.instagram.com/alnokhatha.solutions/',
		icon: iconStyle(FaInstagram),
	},
	// {
	// 	name: 'YouTube',
	// 	link: 'https://www.instagram.com/alnokhatha.solutions/',
	// 	icon: iconStyle(FaYoutube),
	// },
	// {
	// 	name: 'Twitter',
	// 	link: 'https://www.instagram.com/alnokhatha.solutions/',
	// 	icon: iconStyle(FaTwitter),
	// },
	// {
	// 	name: 'LinkedIn',
	// 	link: 'https://www.instagram.com/alnokhatha.solutions/',
	// 	icon: iconStyle(FaLinkedin),
	// },
];

export const footerData = [
	{
		title: 'Main',
		links: [
			{"name":"Home", "page":"", "d":'/'},
			{"name":"Features", "page":"", "d":'Features'},
			{"name":"Series", "page":"", "d":'Series'},
		],
	},
	{
		title: 'Others',
		links: [
			{"name":"Products", "page":"/Product", "d":'/'},
			{"name":"About", "page":"/About", "d":'Features'},
			{"name":"Contact", "page":"/Contact", "d":'Series'},
		],
		// links: ['Login', 'Personal', 'Business', 'Team'],
	},
	{
		title: 'Contact',
		// links: ['Logos', 'Events', 'Stories', 'Office'],
		links: [
			{"name":"Mob : +971 56 515 0555", "page":"/Contact", "d":'/'},
			{"name":"Tel : +971 6 538 8888", "page":"/Contact", "d":'Features'},
			{"name":"Email : info@cctvuae.ae", "page":"/Contact", "d":'Series'},
		],

	},

];
