export const DivSliderData = [
	{
		title: 'IPC9312LFW-AF28-2X4',
		head: '2*2MP LightHunter Dual-lens Network PTZ camera',
		features: [
			'Fixed camera and PTZ camera integration',
			'1920*1080@25fps, 4X optical zoom of PTZ channel',
			'LightHunter technology',
			'Built-in 2*mic & high-power speaker, two-way audio',
			'IR & warm light illumination',
			'Support ceiling mount and wall mount',
		],
		image: './assets/slider/sl1.png',
		link: '',
	},
	{
		title: 'ColorHunter Series',
		head: '4MP HD Intelligent ColorHunter Fixed Network Camera',
		features: [
			'ColorHunter maintain the true color in low-light environment',
			'4MP(2688*1520)@30fps, 16:9 picture improves user experience',
			'F1.0 super large aperture collects more light',
			'1/1.8”progressive backside-illuminated CMOS sensor, more sensitive to light',
			'Built-in advanced chip and exclusive algorithm reduce false alarm',
			'Warm LEDs guarantee lower light pollution',
		],
		image: './assets/slider/sl2.png',
		link: '',
	},
	{
		title: 'NVR304-E2 Series',
		head: 'The Most Powerful NVR in Easy Series',
		features: [
			'More intelligent functions for wider scenarios',
			'SIP, LPR, people counting, face recognition, heatmap, fisheye dewarping, POS, independent output',
			'Higher incoming bandwidth: 320Mbps',
			'Up to 32 channel 4K IP cameras can be connected',
			'Stronger decoding ability: 2ch 4K, 5ch 4MP, 10ch 1080P',
			'Support simultaneous playback of more high-resolution cameras',
		],
		image: './assets/slider/sl3.png',
		link: '',
	},
	{
		title: 'VX1848-V2',
		head: '4U 48 Bay High Performance Storage server',
		features: [
			'512 channels IPC storage',
			'VMS inside',
			'Support SAN/NAS architecture',
			'Unique dust-proof and sulfur-proof design',
			'18 years of R&D experience, up to 200 patents.',
		],
		image: './assets/slider/sl4.png',
		link: '',
	},
];