export const heroOne = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Founded in 2020',
	},
	headline: "We've been in business for 3+ Years",
	description: 'We are providing Security solutions since 2020, Uniview thinks it has to be innovation ability.',
	buttonLabel: 'Contact Us',
	linkTo: '/Contact',
	imgStart: 'start',
	img: './assets/svg/Deal.svg',
	start: 'true',
};

export const heroTwo = {
	reverse: false,
	inverse: false,
	topLine: {
		text: 'Designed & Developed',
	},
	headline: 'The best practices',
	description: 'Our clients have had the best experience working with us',
	buttonLabel: 'View Products',
	linkTo: '/Product',
	imgStart: 'start',
	img: './assets/svg/Connection.svg',
	start: 'true',
};

export const heroThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Highly reputed brand',
	},
	headline: 'Why us? ',
	description:
		'Our 2 year experience have allowed us to use the most innovative technologies and methodologies',
	buttonLabel: 'Know More',

	linkTo: '/',
	imgStart: '',
	img: './assets/svg/ChartUp.svg',
	start: 'true',
};