export const data = [
    {
        to: '/',
        text: 'Home',
        id: 'home',
    },
    {
        to: '/Product',
        text: 'Products & Technology',
    },

    {
        to: '/About',
        text: 'About Us',
    },
  
    {
        to: '/Contact',
        text: 'Contact Us',
    },
];