import React from 'react'
import { MainD, DivC, H_4, H_2, BR  } from './ContactStyles'
import { Container, Col, Row } from 'react-bootstrap';
import { FaPhoneAlt, FaMapMarked, FaEnvelopeOpen } from "react-icons/fa";

export const ContactDiv = () => {
  return (<>
    <MainD>
    <Container fluid>
      <Row>
        <Col md={2}>
          
        </Col>
        <Col md={3}>
            <DivC>
              <H_4><FaPhoneAlt /> Contact</H_4>
              <H_2>+971 56 515 0555</H_2>
              <H_2>+971 65 388 888</H_2>

            </DivC>
        </Col>
        <Col md={3}>
        <DivC>
              <H_4><FaMapMarked /> Address</H_4>
              <H_2>Emirates Industrial Area, AlSaja,    <BR /> Sharjah - UAE</H_2>

          </DivC>
        </Col>
        <Col md={3}>
        <DivC>
        <H_4><FaEnvelopeOpen /> Web / Email</H_4>
            <H_2>www.unvuae.ae</H_2>
            <H_2>info@cctvuae.ae</H_2>

        </DivC>

        </Col>
        <Col md={1}>

        </Col>

      </Row>

    </Container>
    </MainD>
    </>
  )
}
