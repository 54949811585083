import React from 'react'
// import TopSlider from '../components/TopSlider/TopSlider';
import Features from '../components/Features/Features';
import SoltionHeader from '../components/Products/ProductHead/ProductHead';
import ProductList from '../components/Products/ProductList/ProductList';

export default function Solutions() {
  return (
    <>
    <SoltionHeader />
    {/* <TopSlider /> */}
    <ProductList />
    <Features />
    </>
  )
}
