import React from 'react';
// import { Link } from 'react-router-dom';
import {Button, Container, MainHeading} from '../../globalStyles';
import {HeroVideo, HeroSection, HeroText, ButtonWrapper, HeroButton} from './HeroStyles';

import { FiPhoneCall } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";

const Hero = () => {

    const Call = () => {
        window.location.replace('tel:+971565150555')
	};
    const Whatsapp = () => {
        window.location.replace('https://web.whatsapp.com/send?phone=971563800082')
	};
  return (
  <HeroSection>
      <HeroVideo src='./assets/hero.mp4' autoPlay muted/>
      <Container>
          <MainHeading> CCTV Sales, CCTV Installation, CCTV Maintenace, UNV Authorized Dealer And Sharjah Police Approved Company.</MainHeading>
          <HeroText>
              ALNOKHATHA Security Solutions
          </HeroText>
          <HeroText>
              +971 56 515 0555 
          </HeroText>
          <ButtonWrapper>
      
                    <Button onClick={() => Call()}> <FiPhoneCall />  Call Now</Button>
        
              <HeroButton onClick={() => Whatsapp()}><FaWhatsapp /> Whatsapp</HeroButton>
          </ButtonWrapper>
      </Container>
  </HeroSection>
  );
}

export default Hero