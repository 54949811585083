import styled from "styled-components";


export const MainD = styled.div`
    margin-top: 71px;
    padding-bottom: 70px;
`;

export const DivC = styled.div`
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
background-color: rgba(250, 235, 215, 0.571);
height: 150px;
width:100%;
text-align:center;
border-radius: 10px;
padding: 20px;
margin-top: 30px;
margin-bottom: 30px;
word-wrap: break-word;
`;

export const H_4 = styled.h4`
    color: #212121!important;
    font-weight: 600;
`;

export const H_2 = styled.h4`
    color: #071c2f;
    font-weight: 600;
`;

export const BR = styled.br`
`;
