import styled from 'styled-components';

export const PSection = styled.div`
	width:100%;
	background-size: cover;
    background-color: #0c0c0c;
    padding-top: 73px;
    // text-align:center;
`;

export const PText = styled.p`
    color:white;
    margin-left: 107px;
    margin-bottom:0px!important;
`;