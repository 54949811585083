import React from 'react';

import { BsFillShieldLockFill } from 'react-icons/bs';
import { IoIosOptions } from 'react-icons/io';
import { AiOutlineCloudUpload, AiOutlineTool, AiOutlineShoppingCart, AiOutlineFileDone } from 'react-icons/ai';
import { BiSupport, BiDollar } from 'react-icons/bi';
import { GrHostMaintenance } from 'react-icons/gr';
const iconStyle = (Icon) => <Icon size="3rem" color="#0f0f0f" />;

export const featuresData = [
	{
		name: 'Installation',
		description: 'We Provide Installation Services on Your Premises.',
		icon: iconStyle(AiOutlineTool),
		imgClass: 'one',
	},
	{
		name: 'Sales',
		description: 'We Provide High Quality UNV Products On Reasonable Price.',
		icon: iconStyle(AiOutlineShoppingCart),
		imgClass: 'two',
	},
	{
		name: 'Maintenance',
		description: 'Providing Maintenance Services On Demand.',
		icon: iconStyle(GrHostMaintenance),
		imgClass: 'three',
	},
	{
		name: 'Price',
		description: 'We offer the highest value/cost ratio',
		icon: iconStyle(BiDollar),
		imgClass: 'five',
	},
	{
		name: 'Contract / Police Approval',
		description: 'We Provide Maintenance Contract & Police Approval.',
		icon: iconStyle(AiOutlineFileDone),
		imgClass: 'six',
	},
	{
		name: '24/7 Support',
		description: 'Our team is available at all times in case you need us',
		icon: iconStyle(BiSupport),
		imgClass: 'four',
	},
];