export const ProductListData = [
	{
		title: 'IP Camera',
		image: './assets/products/ip_camera.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'PTZ Camera',
		image: './assets/products/ptz_camera.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'Thermal Camera',
		image: './assets/products/thermal_camera.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'Display & Control',
		image: './assets/products/display_control.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'Transmission',
		image: './assets/products/transmission.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'Network Video Recorder',
		image: './assets/products/nvr.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'VMS',
		image: './assets/products/vms.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'Digital Video Recorder',
		image: './assets/products/storage.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'storage & calculation',
		image: './assets/products/dvr.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'Intelligent Computing',
		image: './assets/products/intelligent_computing.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'Heat-Tracker Series',
		image: './assets/products/heat_tracker.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'Accessories',
		image: './assets/products/accessories.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'Client Software',
		image: './assets/products/client_software.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'Uniarch',
		image: './assets/products/uniarch.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'Unisnug',
		image: './assets/products/unisnug.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
	{
		title: 'Dedicated Products',
		image: './assets/products/dedicated_products.jpg',
		models: ['Secure accounts', 'Best for small business', 'Easy', 'Affordable', '1GB RAM'],
	},
];